@import '~antd/dist/antd.less';
@layout-header-background: #000090;

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.logo {
  float: left;
  margin-top: 8px;
  width: 50px;
  height: 50px;
}

@primary-color: #00008F;